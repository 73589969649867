<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <!-- <b-row> {{infos}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️La fiche statistique mensuelle des agences de voyage est éditable par les promoteurs d’établissements et leurs agents. Elle permet de collecter des données sur le fonctionnement mensuel de l\'agence.⚪️ La fiche est remplie une fois par mois et est visible par le super utilisateur et les agents du MTCA.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="
                    require('@/assets/images/illustration/Globalization-pana.png')
                  "
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">FICHE STATISTIQUE</h1>
                  <br />
                  <h4 class="m-auto text-center">
                    Agence de voyage / Tours Operator / Agence réceptive
                  </h4>
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="isCheckingForLastForm"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle cx="29" cy="30" r="17" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="-7" y="60" rx="2" ry="2" width="408" height="132"
          /></content-loader>
          <b-card-body v-if="!isCheckingForLastForm" id="entete" class="mt-2">
            <FormHeader
              :input-data="initialsValues"
              @onFormSuccess="
                ($event) => {
                  infos = { ...infos, ...$event };
                }
              "
            />
          </b-card-body>
        </b-card>
      </div>
      <h5 class="d-flex justify-content-end">
        Tout les champs avec la mention<RequiredStarComponent /> sont requis
      </h5>
      <b-row v-if="!isCheckingForLastForm">
        <!-- navigation -->
        <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <!-- <b-list-group-item href="#entete">
                  EN TETE
                </b-list-group-item> -->
                <b-list-group-item href="#renseignementsgeneraux">
                  RENSEIGNEMENTS GÉNÉRAUX
                </b-list-group-item>
                <b-list-group-item href="#emplois"> EMPLOIS </b-list-group-item>
                <b-list-group-item href="#expoiltationmensuelle">
                  RENSEIGNEMENTS EXPLOITATION DU MOIS
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
        <!-- body -->
        <b-col lg="9" class="pb-2">
          <!-- RENSEIGNEMENTS GENERAUX  -->
          <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row>
                  <!-- Partie gauche -->
                  <b-col md="6">
                    <b-form-group label="Nom Établissement">
                      <b-form-input
                        v-model="infos.nomEtablissement"
                        type="text"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group label="Nom Promoteur">
                      <b-form-input
                        v-model="infos.nomPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                    <b-form-group label="Adresse Complète Établissement ">
                      <b-form-input
                        v-model="infos.adresseComplete"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Partie droite -->
                  <b-col md="6">
                    <b-form-group label="Numéro IFU">
                      <b-form-input
                        v-model="infos.numIFU"
                        type="text"
                        v-digits-only="{ length: 16 }"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group label="Prénom Promoteur">
                      <b-form-input
                        v-model="infos.prenomsPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                    <b-form-group>
                      <template #label>
                        Nationalité du promoteur<RequiredStarComponent
                          class="mr-2"
                        />
                      </template>

                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                        v-model="infos.nationalitePromoteur"
                        type="text"
                        disabled
                      />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- adresse complète -->
                <b-form-group label="Type de l'établissement">
                  <b-form-input
                    v-model="get_sous_type"
                    type="text"
                    disabled
                  />
                </b-form-group>
                <!-- telephone -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>Numéros de Téléphone</div>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Numéro 1">
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required|length:8"
                      > -->
                      <b-form-input
                        v-model="infos.telephone1"
                        type="text"
                        v-phonenumber-field
                      />
                      <!-- <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Numéro 2">
                      <validation-provider v-slot="{ errors }" rules="length:8">
                        <b-form-input
                          v-model="infos.telephone2"
                          type="text"
                          v-phonenumber-field
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Numéro 3">
                      <validation-provider v-slot="{ errors }" rules="length:8">
                        <b-form-input
                          v-model="infos.telephone3"
                          type="text"
                          v-phonenumber-field
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- type transport -->
                <!-- <b-row class="mt-2">
                  <b-col md="12"> Type Établissement </b-col>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.typeEtablissement"
                      :options="typesEts"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-col
                    v-if="infos.typeEtablissement == 'Autres'"
                    class=""
                    md="12"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Type Établissement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.typeEtablissementAutres"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <!-- agrément -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>
                      Détenez-vous un agrément du Ministère en charge du
                      tourisme ?<RequiredStarComponent class="mr-2" />
                    </div>
                  </b-col>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.agrementMinistere"
                      :options="agrementOptions"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-col
                    v-if="infos.agrementMinistere == 'oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Référence Agrément"
                    >
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model="infos.referenceAgrement"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="invalideAgrement" class="text-danger"
                          >Agrément invalide</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <hr />
                <!-- réservé à l'admin -->
                <!-- <b-form-group
                  class="mt-2"
                  label-cols-lg="3"
                  label="Réservé à l'administration "
                >
                  <b-form-input
                    v-model="infos.resereAdministration"
                    type="text"
                  />
                </b-form-group> -->
              </b-card-body>
            </b-card>
          </div>

          <!-- EMPLOIS  -->
          <div>
            <b-card id="emplois">
              <b-card-header class="d-flex justify-content-between">
                <h3>EMPLOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- employés salariés -->
                <div class="table-responsive text-center">
                  <h5>Nombre d'employés salariés<RequiredStarComponent /></h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                              "
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- nouveeaux employés du mois  -->
                <div class="table-responsive text-center mt-3">
                  <h5>
                    Nombre de nouveaux emplois créés au cours du
                    mois<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salariés recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.hommes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.femmes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(infos.nouveauEmplois.hommes) +
                            normalizeNumericValue(infos.nouveauEmplois.femmes)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- employés non salariés -->
                <div class="table-responsive text-center mt-3">
                  <h5>
                    Nombre d'employés non salariés<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés non salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
          <div>
            <b-card id="expoiltationmensuelle">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- voyages organisés  -->
                <b-row>
                  <b-col md="12" class="mt-2">
                    <h3>
                      1- Voyages organisés et croisières tout
                      compris<RequiredStarComponent />
                    </h3>
                  </b-col>
                  <!-- Partie gauche -->
                  <b-col md="6">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Voyages Internes</th>
                            <th>Nombres</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Voyages sans forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternes.sansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages avec forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternes.avecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Total</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.voyagesInternes.sansFrais +
                                    infos.voyagesInternes.avecFrais
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <!-- Partie droite -->
                  <b-col md="6">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Voyages Internationaux</th>
                            <th>Nombres</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Voyages d'entrée sans forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.entreeSansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages d'entrée avec forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.entreeAvecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages de sortie sans forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.sortieSansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages de sortie avec forfait</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.sortieAvecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Total</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.entreeSansFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.entreeAvecFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.sortieSansFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.sortieAvecFrais
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <!-- recette brutes -->
                <b-row>
                  <b-col md="12" class="mt-2">
                    <h3>2- Recettes brutes<RequiredStarComponent /></h3>
                  </b-col>
                  <!-- Partie gauche -->
                  <b-col md="12">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th>Montant</th>
                            <th>Pourcentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Billetterie</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.billetterie
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.recettesBrutes.billetterie > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages organisés</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.voyagesOrganises
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td
                              v-if="infos.recettesBrutes.voyagesOrganises > 0"
                            >
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.voyagesOrganises
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Croisières Tout Compris</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.croisieresToutCompris
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td
                              v-if="
                                infos.recettesBrutes.croisieresToutCompris > 0
                              "
                            >
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.croisieresToutCompris
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Autres Produits</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.autresProduits
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.recettesBrutes.autresProduits > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.autresProduits
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Recettes Brutes Totales</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.voyagesOrganises
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.croisieresToutCompris
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.autresProduits
                                )
                              }}
                            </td>
                            <td
                              v-if="
                                infos.recettesBrutes.billetterie > 0 ||
                                infos.recettesBrutes.voyagesOrganises > 0 ||
                                infos.recettesBrutes.croisieresToutCompris >
                                  0 ||
                                infos.recettesBrutes.autresProduits
                              "
                            >
                              100 %
                            </td>
                            <td v-else>0 %</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <!-- Commission totales réalisées -->
                <b-row>
                  <b-col md="12" class="mt-2">
                    <h3>
                      3- Commissions totales réalisées<RequiredStarComponent />
                    </h3>
                  </b-col>
                  <!-- Partie gauche -->
                  <b-col md="12">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th>Montant</th>
                            <th>Pourcentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Billetterie</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="infos.commissions.billetterie"
                                  type="number"
                                  v-digits-only="{ length: 16 }"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.billetterie > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages organisés</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.commissions.voyagesOrganises
                                  "
                                  type="number"
                                  v-digits-only="{ length: 16 }"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.voyagesOrganises > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.voyagesOrganises
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Croisières tout compris</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.commissions.croisieresToutCompris
                                  "
                                  type="number"
                                  min="0"
                                  v-digits-only="{ length: 16 }"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td
                              v-if="infos.commissions.croisieresToutCompris > 0"
                            >
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.croisieresToutCompris
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Hébergement (Prière d'exclure les commissions sur
                              des hébergement faisant partie de voyages
                              organisés ou de croisières)
                            </th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="infos.commissions.hebergement"
                                  type="number"
                                  v-digits-only="{ length: 16 }"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.hebergement > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.hebergement
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Commissions sur location d'automobiles (Prière
                              d'exclure les commissions sur des locations
                              d'exclure les commissions sur des locations
                              faisant faisant partie de voyages organisés ou de
                              croisières)
                            </th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.commissions.locationAutomobile
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.locationAutomobile > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.locationAutomobile
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Commission sur primes d'assurances (telles que
                              assurance voyage, assurance sur les bagages et
                              assurance-annulation, etc)
                            </th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.commissions.primesAssurance
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.primesAssurance > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.primesAssurance
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Autres commisions (telles que les attractions, les
                              chèques de voyage, etc) Veuillez préciser
                            </th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.commissions.autresCommissions
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.commissions.autresCommissions > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.commissions.autresCommissions
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.commissions.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.hebergement
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.locationAutomobile
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.primesAssurance
                                  ) +
                                  normalizeNumericValue(
                                    infos.commissions.autresCommissions
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <!-- total -->
                          <tr>
                            <th scope="row">Commissions totales réalisées</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.commissions.billetterie
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.voyagesOrganises
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.croisieresToutCompris
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.hebergement
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.locationAutomobile
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.primesAssurance
                                ) +
                                normalizeNumericValue(
                                  infos.commissions.autresCommissions
                                )
                              }}
                            </td>
                            <td
                              v-if="
                                infos.commissions.billetterie > 0 ||
                                infos.commissions.voyagesOrganises > 0 ||
                                infos.commissions.croisieresToutCompris > 0 ||
                                infos.commissions.hebergement > 0 ||
                                infos.commissions.locationAutomobile > 0 ||
                                infos.commissions.primesAssurance > 0 ||
                                infos.commissions.autresCommissions
                              "
                            >
                              100 %
                            </td>
                            <td v-else>0 %</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <!-- charge salariales  -->
                <b-form-group class="mt-2">
                  <label
                    for="CS"
                    class="float-left mr-5 mx-auto col-form-label col-form-label-md"
                    >Charges salariales (FCFA)<RequiredStarComponent
                      class="mr-2"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-50 ml-2"
                    id="CS"
                  >
                    <b-form-input
                      v-model.number="infos.chargesSalariales"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- autres charges  -->
                <b-form-group class="mt-2">
                  <label
                    for="nationalité"
                    class="float-left mr-5 mx-auto col-form-label col-form-label-md"
                    >Autres Dépenses (FCFA)<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-50 ml-2"
                  >
                    <b-form-input
                      v-model.number="infos.autresDepense"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- <tips-modal-create-from-last-record :canShow="showCreateFromLastModal"></tips-modal-create-from-last-record> -->
    <!-- <TipsModalCreateFromLastRecord /> -->
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilté de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">Créer a partir d'un formulaire vierge</h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
              @click="hideModal"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
  BModal,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
// import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
// imports for validation
// const store = require('store')

import FormHeader from "../components/FormHeader.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    BModal,
    FormHeader,
    RequiredStarComponent,
    // TipsModalCreateFromLastRecord,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    return { requiredStoreModules };
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      userData: utilsService.currentUserUtils().userData,
      userData: utilsService.currentUserUtils().userData,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesEts: [
        { text: "Agence de voyage", value: "Agence de voyage" },
        { text: "Tour Operator", value: "Tour Operator" },
        { text: "Agence réceptive", value: "Agence réceptive" },
      ],
      agrementOptions: ["non", "oui"],
      autofillableFields: [
        {
          name: "annee",
        },
        {
          name: "departement",
        },
        {
          name: "commune",
        },
        {
          name: "arrondissement",
        },
        {
          name: "quartier",
        },
        {
          name: "nomEtablissement",
        },
        {
          name: "numIFU",
        },
        {
          name: "nomPromoteur",
        },
        {
          name: "prenomsPromoteur",
        },
        // {
        //   name: "emailPromoteur",
        // },
        {
          name: "nationalitePromoteur",
        },
        {
          name: "adresseComplete",
          key: "adresseEtablissement",
        },
        {
          name: "telephone1",
          key: "telephoneEtablissement",
        },
        {
          name: "telephone2",
        },
        {
          name: "telephone3",
        },
        {
          name: "typeEtablissement",
        },
        {
          name: "agrementMinistere",
        },
        {
          name: "referenceAgrement",
        },
      ],
      initialsValues: {},
      infos: {
        annee: "",
        mois: "",
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
        nomEtablissement: "",
        numIFU: "",
        nomPromoteur: "",
        prenomsPromoteur: "",
        nationalitePromoteur: "",
        adresseComplete: "",
        telephone1: "",
        telephone2: "",
        telephone3: "",
        typeEtablissement: "",
        agrementMinistere: "",
        referenceAgrement: "",
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        commissions: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          hebergement: null,
          locationAutomobile: null,
          primesAssurance: null,
          autresCommissions: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },

      // validOutput: {
      //   annee: '',
      //   mois: '',
      //   departement: '',
      //   commune: '',
      //   arrondissement: '',
      //   quartier: '',
      //   nomEtablissement: '',
      //   numIFU: '',
      //   nomPromoteur: '',
      //   prenomsPromoteur: '',
      //   nationalitePromoteur: '',
      //   adresseComplete: '',
      //   telephone1: '',
      //   typeEtablissement: '',
      //   agrementMinistere: '',
      //   referenceAgrement: '',
      //   employesSalaries: {
      //     hommeTempspleinNationaux: 1,
      //     hommeTempspartielNationaux: 3,
      //     hommeTempspleinEtrangers: 3,
      //     hommeTempspartielEtrangers: 3,
      //     femmeTempspleinNationaux: 3,
      //     femmeTempspartielNationaux: 3,
      //     femmeTempspleinEtrangers: 3,
      //     femmeTempspartielEtrangers: 3,
      //   },
      //   nouveauEmplois: {
      //     hommes: 3,
      //     femmes: 3,
      //   },
      //   employesNonSalaries: {
      //     hommeTempspleinNationaux: 3,
      //     hommeTempspartielNationaux: 1,
      //     hommeTempspleinEtrangers: 3,
      //     hommeTempspartielEtrangers: 3,
      //     femmeTempspleinNationaux: 3,
      //     femmeTempspartielNationaux: 3,
      //     femmeTempspleinEtrangers: 3,
      //     femmeTempspartielEtrangers: 3,
      //   },
      //   voyagesInternes: {
      //     sansFrais: 3,
      //     avecFrais: 3,
      //   },
      //   voyagesInternationaux: {
      //     entreeSansFrais: 3,
      //     entreeAvecFrais: 3,
      //     sortieSansFrais: 3,
      //     sortieAvecFrais: 3,
      //   },
      //   recettesBrutes: {
      //     billetterie: 3,
      //     voyagesOrganises: 3,
      //     croisieresToutCompris: 3,
      //     autresProduits: 3,
      //   },
      //   commissions: {
      //     billetterie: 3,
      //     voyagesOrganises: 2,
      //     croisieresToutCompris: 3,
      //     hebergement: 3,
      //     locationAutomobile: 3,
      //     primesAssurance: 3,
      //     autresCommissions: 3,
      //   },
      //   chargesSalariales: 3,
      //   autresDepense: 3,
      // },

      // agrements: [
      //   { etablissement: 'Les Hirondelles', reference: 'hg7h878jhjKJ' },
      // ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
    get_sous_type() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      return sousTypeEtablissement;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.annees = utilsService.getListYears();
        this.mois = utilsService.getListMonths();
        this.pays = utilsService.getListCountries();

        // const userId = localstorageService.getUserId()

        if (this.formInput) {
          // this.isCheckingForLastForm = false
          // this.isFillingForm = true
          // this.infos = { ...this.formInput, ...autofillableFields }
          // setTimeout(() => {
          //   this.isFillingForm = false
          // }, 1000)

          this.isCheckingForLastForm = false;
          this.isFillingForm = true;
          this.infos = { ...this.formInput };
          this.initialsValues = this.customCloneDeep(this.infos);

          setTimeout(() => {
            this.isFillingForm = false;
          }, 1000);
        } else {
          const etablissementDetails = this.etablissementDetails || {};
          const autofillableFields = {};
          this.autofillableFields.map((e) => {
            let foundItem = etablissementDetails[e.name];
            if (!foundItem) foundItem = etablissementDetails[e.key];
            if (foundItem) {
              autofillableFields[e.name] = foundItem;
            }
            return e;
          });

          // autofillableFields.annee = new Date().getFullYear()
          // autofillableFields.mois = this.mois[new Date().getMonth()]

          this.infos = { ...this.infos, ...autofillableFields };
          this.initialsValues = this.customCloneDeep(this.infos);
          this.initialsValues = this.customCloneDeep(this.infos);

          setTimeout(() => {
            this.isCheckingForLastForm = false;
          }, 1000);
        }
        // this.mois = this.removeinvalidMonth(this.mois)
      },
    },
    "infos.departement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "infos.commune": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "infos.arrondissement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.quartier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    this.getListTypesEtablissement();
    this.loadDepartments();
    this.loadCommunes();
    this.loadArrondissements();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    // --------------------------------------------------------------------
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },

    loadCommunes() {
      this.action_loadCommunes(this.infos.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.infos.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.infos.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    },
    sendToPreview() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          const canSend = true;
          // clearing data
          const form = {
            ...this.infos,
            typeEtablissement: sousTypeEtablissement,
            annee: `${this.infos.annee}`,
            ...(this.infos.departement.name && {
              departement: this.infos.departement.name,
            }),
            ...(this.infos.commune.name && {
              commune: this.infos.commune.name,
            }),
            ...(this.infos.arrondissement.name && {
              arrondissement: this.infos.arrondissement.name,
            }),
            ...(this.infos.quartier.name && {
              quartier: this.infos.quartier.name,
            }),
            ...(this.infos.nationalitePromoteur.name && {
              nationalitePromoteur: this.infos.nationalitePromoteur.name,
            }),
          };

          if (form.telephone2 == "") delete form.telephone2;
          if (form.telephone3 == "") delete form.telephone3;
          if (form.qrcode) delete form.qrcode;
          if (form._id) delete form._id;

          // check validity of agrement
          // if (form.agrementMinistere == "non") {
          //   delete form.referenceAgrement;
          // } else {
          //   (this.mauvaisEtablissement = false),
          //     (this.invalideAgrement = false);
          //   this.agrements.forEach((agrement, index) => {
          //     if (agrement.reference == form.referenceAgrement) {
          //       if (agrement.etablissement != form.nomEtablissement) {
          //         this.mauvaisEtablissement = true;
          //         return;
          //       } else {
          //         this.invalideAgrement = false;
          //         return;
          //       }
          //     } else {
          //       this.invalideAgrement = true;
          //     }
          //   });
          //   if (this.invalideAgrement || this.mauvaisEtablissement) {
          //     canSend = false;
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: this.mauvaisEtablissement
          //           ? "Mauvais établissement associé à l'agrément"
          //           : "Agrément invalide",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //       },
          //     });
          //   }
          // }

          // check validity of IFU
          // if (form.numIFU != "") {
          //   this.invalideIfu = false;
          //   const res = await this.checkIFU({
          //     numeroIfu: form.numIFU,
          //   });

          //   if (!res.data.success) {
          //     canSend = false;
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: "Numéro IFU Invalide",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //       },
          //     });
          //     this.invalideIfu = true;
          //   }
          // }

          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          if (errorFirst) {
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
